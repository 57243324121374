import _is from "./is";
import _to from "./to";
import _owner from "./owner";
var exports = {};
var is = _is,
    to = _to,
    owner = _owner;

exports = function log(ns) {
  return function (d) {
    if (!owner.console || !console.log.apply) return d;
    is.arr(arguments[2]) && (arguments[2] = arguments[2].length);
    var args = to.arr(arguments),
        prefix = "[log][" + new Date().toISOString() + "]" + ns;
    args.unshift(prefix.grey ? prefix.grey : prefix);
    return console.log.apply(console, args), d;
  };
};

export default exports;